.Title {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
}

.Content {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
}

.Subtitle {
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.Badge {
    margin-left: 20px;
    font-size: 14px;
    color: var(--color-primary);
}

.Section {
    width: 33%;
    padding: 20px 20px 20px 0;
    box-sizing: border-box;
}

.SectionTitle {
    font-weight: 500;
}

.SectionText {
    font-size: 14px;
    color: #666;
    margin-top: 10px;
}

.InfoBoxText {
    margin-bottom: 20px;
    font-size: 14px;
}

.ActionButtons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.ActionButton {
    padding: 10px 20px;
}

.ActionButtonSecondary {
    background: none;
    color: black;
    margin-left: 20px;
    font-weight: 500;
    font-size: 15px;
}

.ActionButtonSecondary:hover {
    background: none;
}

.NoSubscription {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.NoSubscriptionText {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
}

.ButtonPrimary {
    padding: 10px 15px;
    border-radius: 30px;
}

@media (max-width: 700px) {
    .Title {
        display: none;
    }
}