.Container {
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 var(--desktop-container-padding);
    box-sizing: border-box;
}

.Content {
    width: 100%;
    box-sizing: border-box;
}

.Title {
    font-size: 35px;
    font-weight: 700;
    margin: 20px 0 10px 0;
    padding: 0;
}

.Subtitle {
    color: #68676C;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
}

.Coaches {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: 20px 0;
}

.CoachCardWrapper {
    margin: 5px 20px 20px 0;
    width: calc(25% - 20px);
    min-height: 100px;
}

@media (max-width: 1000px) {
    .CoachCardWrapper {
        width: calc(33% - 20px);
    }
}

@media (max-width: 700px) {
    .Container {
        padding: 0 var(--mobile-container-padding);
    }

    .CoachCardWrapper {
        width: 100%;
        margin: 5px 0;
    }

    .Subtitle {
        font-size: 18px;
    }
}
