.Container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.ContainerInner {
    max-width: 1000px;
    width: 100%;
}

.Title {
    font-size: 35px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
}


.AnswerSectionTitle {
    font-weight: bold;
    margin: 10px 0;
}

.List {
    padding-left: 15px;
}

.Link {
    color: var(--color-primary);
}

@media (max-width: 700px) {
    .Title {
        font-size: 30px;
    }
}