@media (min-width: 701px) {
    .Mobile {
        display: none;
    }
}

@media (max-width: 700px) {
    .Desktop {
        display: none;
    }
}