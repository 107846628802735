.Container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 50px 0;
}

.Highlight {
    color: var(--color-primary);
}

.Content {
    background: url("../../../assets/WhyImpruviBackground.jpg") no-repeat;
    background-size: cover;
    width: 100%;
    padding: 60px var(--desktop-container-padding);
}

.ContentInner {
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
}

.ActionButton {
    margin-top: 40px;
    font-weight: 600;
    display: inline-block;
    padding: 12px 40px;
}

.Sections {
    padding: 150px 0 100px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: white;
}

.Section {
    width: calc(50% - 15px);
}

.Icon {
    width: 30px;
    margin-right: 15px;
}

h2 {
    font-size: 50px;
    margin: 0;
    width: 75%;
}

h3 {
    width: 100%;
    margin: 0;
    font-size: 40px;
}

h4 {
    font-size: 23px;
    margin: 30px 0 10px 0;
    display: flex;
    align-items: center;
}

@media (max-width: 1000px) {
    .Sections {
        padding: 200px 0 100px 0;
    }

    .Section {
        width: 100%;
    }
}
