.Container {
    max-width: 1000px;
    width: 100%;
    margin: 20px auto 100px auto;
    padding: 0 var(--desktop-container-padding);
    box-sizing: border-box;
    flex-wrap: wrap;
    display: flex;
}

.Row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
}

.TwoThirds {
    width: calc(66% - 15px);
    margin-right: 15px;
    margin-bottom: 20px;
    min-height: 475px;
}

.OneThird {
    width: calc(33% - 15px);
    margin-right: 15px;
    margin-bottom: 20px;
    min-height: 475px;
}

@media (max-width: 700px) {
    .TwoThirds, .OneThird {
        width: 100%;
        margin-right: 0;
        min-height: 0;
    }
}

