.Container {
    width: 100%;
    padding: 10px;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 3px;
}

.Option {
    width: 100%;
    padding: 10px;
    background-color: white;
}

