.Container {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 10;
    background-color: black;
    padding: 0 20px;
    box-sizing: border-box;
}

.ContainerSticky {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 10;
    background-color: black;
    padding: 0 20px;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 0 4px 9px -6px rgba(0,0,0, 0.2);
}

.NavRightOption {
    padding: 5px 10px;
    margin: 0 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    color: white;
}

.NavRightOption:hover {
    opacity: .6;
    transition: opacity .2s;
}

.Hamburger {
    width: 35px;
    height: 35px;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.Hamburger:hover {
    background-color: #222;
}

.Hamburger img {
    width: 25px;
    object-fit: contain;
}
