.Container {
    width: 100%;
    position: relative;
    cursor: pointer;
}

.Video {
    width: 100%;
    border-radius: 10px;
}


.PlayIconOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PlayIconOverlay img {
    width: 50px;
}
