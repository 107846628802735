.Container {
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 30px var(--desktop-container-padding);
    box-sizing: border-box;
    flex-wrap: wrap;
}

.Navigation {
    width: 180px;
}

.NavOption {
    cursor: pointer;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    color: #666;
    font-size: 16px;
    font-weight: 500;
}

.NavOption:hover {
    color: black;
}

.NavOptionActive {
    font-weight: 500;
    color: var(--color-primary) !important;
}

.Content {
    width: calc(100% - 180px);
    box-sizing: border-box;
}

@media (max-width: 700px) {
    .Container {
        padding: 10px var(--mobile-container-padding);
    }

    .Navigation {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        font-size: 14px;
    }

    .NavOption {
        width: fit-content;
        text-align: center;
        font-weight: 500;
        border-radius: 30px;
        margin-right: 10px;
    }

    .NavOptionActive {
        padding: 7px 15px;
        font-weight: 500;
        color: white !important;
        background-color: var(--color-primary);
    }

    .Content {
        width: 100%;
    }

    .MobileHidden {
        display: none;
    }
}