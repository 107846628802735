.Container {
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px 10px;
}

.SectionTitle {
    font-weight: 600;
    margin: 20px 0;
}

.AppStoreActionButtonWrapper {
    display: flex;
}

.Option {
    cursor: pointer;
    margin: 20px 0;
}

.Option:hover {
    text-decoration: underline;
}


@media (max-width: 700px) {
    .Section {
        min-width: 50%;
    }
}