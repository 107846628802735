.ContainerOuter {
    width: 100%;
    position: relative;
}

.Container {
    width: 100%;
    background-color: white;
    overflow: hidden;
    font-size: 15px;
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 3px;
    box-sizing: border-box;
    position: relative;
}

.TypeError {
    position: absolute;
    right: 0;
    top: -23px;
    font-size: 12px;
    font-weight: 400;
    color: #6e7891;
}

.Error {
    color: #D93025;
    font-size: 12px;
    margin-bottom: 5px;
    width: 100%;
    text-align: left;
    margin-top: 5px;
}

.Error i {
    margin-right: 3px;
}

.ContainerError {
    outline: 2px solid #D93025 !important;
    border: none;
}

.Container input {
    font-size: 16px;
    padding: 15px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    outline: none;
    border: none;
}

.Container input::placeholder {
    font-size: 14px;
    color: #ccc;
}

.CharacterCounter {
    position: absolute;
    right: 0;
    bottom: -20px;
    font-size: 12px;
    font-weight: 400;
}
