.Container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px var(--desktop-container-padding);
    box-sizing: border-box;
}

.Card {
    max-width: 1000px;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    background-color: #F6F6F6;
}

.CardLeft {
    width: 50%;
    overflow: hidden;
    position: relative;
}

.CardLeft img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.CardRight {
    width: 50%;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
}

.HeaderText {
    font-size: 25px;
    width: 100%;
    flex-wrap: wrap;
}

.Title {
    font-size: 40px;
    font-weight: 700;
    margin: 10px 0;
}

.SubTitle {
    font-size: 20px;
    margin: 10px 0;
}

.Break {
    height: 1px;
    width: 100%;
    background-color: black;
    margin: 30px 0 10px 0;
}
@media (max-width: 700px) {
    .Row {
        display: flex;
        justify-content: space-between;
        color: #666;
        width:100%;
    }
}
@media (max-width: 350px) {
    .Row {
        display: flex;
        justify-content: space-between;
        color: #666;
        display:block;
        width:100%;
    }
}

@media (min-width: 700px) {
    .Row {
        display: flex;
        justify-content: space-between;
        color: #666;
        margin: 10px;
    }
}


.Button {
    padding: 10px 15px;
    font-weight: 600;
    margin-top: 30px;
    border-radius: 40px;
    display: block;
}

@media (max-width: 700px) {
    .HeaderText {
        font-size: 20px;
    }

    .Title {
        font-size: 30px;
        margin: 5px 0;
    }

    .SubTitle {
        font-size: 18px;
        margin: 10px 0;
    }

    .Container {
        padding: 30px var(--mobile-container-padding);
    }

    .CardLeft {
        width: 100%;
        max-height: 300px;
    }

    .CardRight {
        width: 100%;
    }
}