.Container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ddd;
    overflow: hidden;
}

.Container img {
    object-fit: cover;
    width: 100%;
    background-color: white;
    margin: 0;
}