.Container {
    width: 100%;
    max-width: 1000px;
    padding: 0 var(--desktop-container-padding);
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}

.Section {
    margin: 30px 0;
}

.Container div div {
    color: #555;
    margin: 5px 0;
}

.Bold {
    font-weight: 500;
}

@media (max-width: 700px) {
    .Container {
        padding: 0 var(--mobile-container-padding);
    }
}