.Container {
    background-color: var(--color-primary);
    color: white;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 20px;
    min-height: 475px;
}

.Title {
    font-size: 25px;
    text-align: center;
    font-weight: 600;
}

.DividerContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px 0;
}

.Divider {
    background-color: white;
    height: 2px;
    width: 60%;
}

.Label {
    padding: 5px 0;
}

.TextInput {
    border: none;
    border-radius: 3px;
}

.Error {
    background-color: white;
    border-radius: 3px;
    padding: 10px;
    box-sizing: border-box;
    color: var(--color-primary);
    margin-top: 10px;
    font-size: 14px;
}

.SubText {
    font-size: 15px;
    padding: 10px 0;
    font-weight: 300;
}

.SubText a {
    color: white;
    text-decoration: none;
    font-weight: 600;
}

.ActionButton {
    background-color: white;
    color: black;
    font-weight: 500;
    margin-top: 10px;
}

.ActionButton:hover {
    background-color: #eee;
}

.CheckIcon {
    margin-left: 7px;
}

@media (max-width: 700px) {
    .Container {
        width: 100%;
        margin-right: 0;
        min-height: 0;
    }
}
