.PopUp {
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .2);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
}

.PopUpInner {
    width: fit-content;
    height: fit-content;
    animation: fade .2s ease-out;
    box-shadow: 0 4px 20px 1px rgba(0,0,0, 0.3);
    border-radius: 10px;
    overflow: hidden;
}

@keyframes fade {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
}

@media (max-width: 700px) {
    .PopUpInner {
        border-radius: 0;
    }
}