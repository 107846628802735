.Container {
    width: 100%;
    padding: 30px 0;
    box-sizing: border-box;
}

.Content {
    padding: 0 var(--desktop-container-padding);
    box-sizing: border-box;
}

.Title {
    font-size: 55px;
    text-align: center;
    width: 100%;
    font-weight: 700;
}

.Highlight {
    color: var(--color-primary);
}

.Subtitle {
    font-size: 20px;
    text-align: center;
    width: 100%;
    max-width: 500px;
    margin: 15px auto 40px auto;
}

@media (max-width: 700px) {
    .Content {
        padding: 0 var(--mobile-container-padding);
    }

    .Title {
        font-size: 30px;
    }

    .Subtitle {
        font-size: 16px;
    }
}