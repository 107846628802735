.Options {
    text-align: center;
    font-size: 14px;
    width: 100%;
    padding-top: 10px;
}

.Option {
    text-align: center;
    font-size: 13px;
    margin: 5px 0;
    color: #555;
}

.LabelUnderline {
    text-decoration: underline;
    cursor: pointer;
}

.Error {
    color: #D93025;
    font-size: 14px;
    margin-top: 10px;
}