.Title {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
}

.QuestionContainer {
    margin: 30px 0 15px 0;
}

.Question {
    font-size: 18px;
    font-weight: 500;
}

.Answer {
    font-size: 14px;
    color: #666;
}

@media (max-width: 700px) {
    .Title {
        display: none;
        margin-bottom: 0;
    }

    .QuestionContainer {
        margin: 0 0 15px 0;
    }
}