.Content {
    padding: 0 40px;
    box-sizing: border-box;
}

.MosaicContainer {
    width: 100vw;
    max-height: 60vh;
    overflow: hidden;
    margin-bottom: 20px;
}

.MosaicImage {
    width: 100%;
    object-fit: cover;
}

.Title {
    font-size: 36px;
    font-weight: 800;
    text-align: center;
    margin: 0;
    padding: 0;
}

.Subtitle {
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
    font-weight: 500;
    color: #666;
}

.ActionButtons {
    justify-content: center;
    margin: 30px 0;
}

.ActionButtonWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 10px 0;
}

.ButtonPrimary {
    padding: 12px 15px;
    border-radius: 40px;
    font-weight: 600;
}

.ButtonPrimary img {
    width: 15px;
    margin-left: 10px;
}

@media (max-width: 700px) {
    .Content {
        padding: 0 20px;
    }
}
