.Container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.Header {
    background: url("../../assets/CoachesMosaicDark.jpg") no-repeat;
    background-size: cover;
    width: 100%;
    padding: 150px 0 75px 0;
    box-sizing: border-box;
}

.HeaderContent {
    max-width: 1000px;
    padding: 0 var(--desktop-container-padding);
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.HeaderTitle {
    color: white;
    font-size: 70px;
    font-weight: 700;
    text-align: center;
}

.HeaderApplyButton {
    background-color: white;
    padding: 13px 50px;
    font-weight: 600;
    margin-top: 50px;
    color: black;
}

.HeaderApplyButton:hover {
    opacity: .8;
    background-color: white;
}

.Goal {
    width: 100%;
}

.GoalContent {
    max-width: 1000px;
    padding: 75px var(--desktop-container-padding);
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.GoalLeft {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.GoalLeftTitle {
    font-size: 40px;
    font-weight: 700;
    width: 100%;
    text-align: center;
}

.GoalApplyButton {
    padding: 13px 50px;
    font-weight: 600;
    margin-top: 25px;
}

.GoalRight {
    width: 50%;
    font-size: 18px;
}

.Quotes {
    background-color: #F2F2F2;
    width: 100%;
}

.QuotesContent {
    max-width: 1000px;
    padding: 0 var(--desktop-container-padding);
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
}

.FooterWrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 0 var(--desktop-container-padding);
}

@media (max-width: 1100px) {
    .HeaderTitle {
        font-size: 50px;
    }
}

@media (max-width: 700px) {
    .Header {
        padding: 75px 0 50px 0;
    }

    .HeaderTitle {
        font-size: 33px;
    }

    .GoalLeft {
        width: 100%;
        margin-bottom: 20px;
    }

    .GoalRight {
        width: 100%;
    }

    .FooterWrapper {
        padding: 0 var(--mobile-container-padding);
    }
}
