.Container {
    display: flex;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 20px;
}

.Left {
    width: 550px;
    padding: 0 20px 0 60px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.Right {
    width: calc(100% - 550px);
    display: flex;
    align-items: center;
    position: relative;
}

.MosaicContainer {
    width: 100%;
}

.MosaicImage {
    width: 100%;
    height: 100%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    object-fit: contain;
}

.Title {
    font-size: 55px;
    font-weight: 800;
}

.Subtitle {
    font-size: 22px;
    margin-top: 10px;
    color: #666;
}

.ActionButtons {
    margin: 30px 0;
}

.ActionButtonWrapper {
    display: flex;
    margin: 10px 0;
}

.ButtonPrimary {
    padding: 12px 15px;
    border-radius: 40px;
    font-weight: 600;
}

.ButtonPrimary img {
    width: 15px;
    margin-left: 10px;
}

@media (max-width: 1000px) {
    .Left {
        width: 350px;
    }

    .Right {
        width: calc(100% - 350px);
    }

    .Title {
        font-size: 45px;
    }

    .Subtitle {
        font-size: 18px;
    }
}