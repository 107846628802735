.Container {
    background-color: black;
    width: 100%;
    min-height: 100vh;
    z-index: -1;
    padding: 50px 30px;
    box-sizing: border-box;
    color: white;
}

.Content {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.Overview {
    width: 100%;
    box-sizing: border-box;
    color: white;
}

.Title {
    font-size: 50px;
    font-weight: 600;
}

.Subtitle {
    margin: 5px 0 25px 0;
    font-size: 20px;
}

.Bullets {
    margin: 20px 0;
}

.Bullet {
    display: flex;
    align-items: center;
    margin: 20px 0;
    font-weight: 600;
}

.Bullet img {
    width: 28px;
    height: 28px;
    object-fit: contain;
    margin-right: 15px;
}

.ActionButtons {
    display: flex;
    align-items: center;
    margin: 40px 0 30px 0;
}

.ButtonPrimary {
    font-weight: 600;
    padding: 12px 10px;
    border-radius: 30px;
    margin-right: 10px;
    font-size: 14px;
}

.ButtonPrimary img {
    width: 15px;
    margin-left: 10px;
}

.ButtonSecondary {
    font-weight: 600;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
    opacity: 1;
    transition: opacity .1s linear;
}

.ButtonSecondary:hover {
    opacity: .7;
    transition: opacity .1s linear;
}

.TabsWrapper {
    margin: 50px 0 100px 0;
}

.SpinnerContainer {
    width: 100%;
    padding: 100px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}