.Container {
    padding: 50px 30px;
    box-sizing: border-box;
    width: 100%;
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
}

.ActiveSlide {
    display: flex;
    flex-wrap: wrap;
    opacity: 1;
    transition: opacity 300ms linear;
}

.TransitioningSlide {
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    transition: opacity 300ms linear;
}

.HiddenSlide {
    display: none;
    opacity: 0;
    transition: opacity 300ms linear;
}

.SlideContainer {
    width: 100%;
}

.ProgressBarContainer {
    width: 100%;
    background-color: #eee;
    height: 5px;
    border-radius: 20px;
    margin-bottom: 50px;
}

.ProgressBarInner {
    background-color: var(--color-primary);
    height: 5px;
    border-radius: 20px;
    transition: width .2s linear;
}

.SpinnerContainer {
    width: 100%;
    padding: 100px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}