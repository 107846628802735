.Title {
    font-size: 23px;
    font-weight: 600;
    margin: 10px 0 30px 0;
}

.NumberOfTrainings {
    padding-bottom: 20px;
}

.NumberOfTrainingsLabel {
    margin-bottom: 6px;
    font-weight: 500;
}

.AmountDue {
    display: flex;
    justify-content: space-between;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #ddd;
    font-weight: 600;
}

.SubText {
    font-size: 12px;
    color: #888;
    margin: 10px 0;
}

.SubText span {
    cursor: pointer;
    text-decoration: underline;
    white-space: pre-wrap;
}

.Form {
    margin-top: 30px;
}

.SubmitButtonWrapper {
    margin-top: 15px;
}

.Error {
    color: #D93025;
    font-size: 14px;
    margin-top: 10px;
}
