.Container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.HowItWorksWrapper {
    width: 100%;
}

.FrequentlyAskedQuestionsWrapper {
    padding: 80px var(--desktop-container-padding) 40px var(--desktop-container-padding);
}

.PaddedContainer {
    width: 100%;
    padding: 0 var(--desktop-container-padding);
    box-sizing: border-box;
}

@media (max-width: 700px) {
    .FrequentlyAskedQuestionsWrapper {
        padding: 60px var(--mobile-container-padding) 40px var(--mobile-container-padding);
    }

    .PaddedContainer {
        padding: 0 var(--mobile-container-padding);
    }
}