.ButtonApple {
    background-color: black;
    color: white;
    font-weight: 600;
    padding: 8px 20px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    cursor: pointer;
    opacity: 1;
    transition: opacity .1s linear;
}

.ButtonAppleDark {
    background-color: white;
    color: black;
}

.ButtonApple:hover {
    opacity: .7;
    transition: opacity .1s linear;
}

.ButtonApple img {
    height: 25px;
    width: auto;
    margin-right: 10px;
}

.AppleTextSmall {
    font-size: 10px;
    font-weight: 400;
}

.AppleTextLarge {
    font-size: 14px;
}