.Container {
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 var(--desktop-container-padding) 100px var(--desktop-container-padding);
    box-sizing: border-box;
}

.Content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.Title {
    width: 100%;
    font-size: 35px;
    font-weight: 700;
    margin: 20px 0;
}

.SpinnerContainer {
    width: 100%;
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Left {
    width: calc(50% - 40px);
}

.Right {
    width: calc(50% - 40px);
}

@media (max-width: 700px) {
    .Container {
        padding: 0 var(--mobile-container-padding) 100px var(--mobile-container-padding);
    }

    .Left {
        width: 100%;
    }

    .Right {
        width: 100%;
        margin-top: 50px;
    }
}