.Container {
    width: 100%;
    background-color: #F6F6F6;
    padding: 40px var(--desktop-container-padding);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.ActiveSlide {
    display: flex;
    flex-wrap: wrap;
    opacity: 1;
    transition: opacity 300ms linear;
    -webkit-transition-property: opacity;
    -webkit-transition-duration: 300ms;
}

.TransitioningSlide {
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    -webkit-transition-property: opacity;
    -webkit-transition-duration: 300ms;
    transition: opacity 300ms linear;
}

.HiddenSlide {
    display: none;
    opacity: 0;
    -webkit-transition-property: opacity;
    -webkit-transition-duration: 300ms;
    transition: opacity 300ms linear;
}

.AppStoreActionButtonWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
}


@media (min-width: 800px) {
    .MobileWrapper {
        display: none;
    }
}


@media (max-width: 800px) {
    .DesktopWrapper {
        display: none;
    }
}