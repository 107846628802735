.Container {
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 var(--desktop-container-padding);
    box-sizing: border-box;
    padding-top: 30px;
}

.Title {
    font-size: 35px;
    font-weight: 700;
    width: 100%;
    margin-bottom: 5px;
}

.Subtitle {
    color: #68676C;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
}

.Plans {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
}

.PlanWrapper {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    box-sizing: border-box;
}

@media (max-width: 1000px) {
    .Plans {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .Plans {
        grid-template-columns: repeat(1, 1fr);
    }

    .Container {
        padding: 0 var(--mobile-container-padding);
    }
}