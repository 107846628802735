.PaymentMethodWrapper {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.InputLabel {
    font-size: 12px;
    font-weight: 500;
    margin: 10px 0 5px 0;
    color: #666;
}

.NameInput {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
}

.NameInput::placeholder {
    color: #bbb;
}

.CardInputWrapper {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 10px;
    box-sizing: border-box;
}
