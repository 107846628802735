.Container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.SectionLarge {
    padding: 0 20px 20px 0;
    box-sizing: border-box;
}

.SectionSmall {
    padding: 0 20px 20px 0;
    box-sizing: border-box;
}

.SectionTitle {
    font-weight: 500;
    margin-bottom: 10px;
}

.SectionText {
    font-size: 14px;
    color: #666;
}

.PlanHeader {
    display: flex;
    margin-top: 10px;
    font-size: 14px;
}

.Overview {
    margin-left: 10px;
    flex: 1;
}

