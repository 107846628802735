.Left {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    font-size: 60px;
    font-weight: 600;
    padding: calc(35vh - 30px) 30px 30px 30px;
    box-sizing: border-box;
    text-align: end;
}

.Right {
    display: flex;
    flex: 1;
    font-size: 60px;
    font-weight: 600;
    padding: calc(35vh - 30px) 30px 30px 30px;
    box-sizing: border-box;
    flex-wrap: wrap;
    align-items: flex-start;
}

.Subtitle {
    font-size: 20px;
    font-weight: 400;
    margin-top: 15px;
    color: #666;
}

.Arrows {
    width: 100%;
    display: flex;
    align-items: center;
}

.ArrowContainer {
    padding: 10px;
}

.Arrows img {
    width: 50px;
    margin: 0 10px;
}

.Arrow {
    cursor: pointer;
}

.PhoneImage {
    max-height: 800px;
    height: 80vh;
    margin-right: -5vh;
}

.Footer {
    width: 100%;
}