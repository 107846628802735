.Title {
    font-size: 23px;
    font-weight: 600;
    margin: 10px 0 30px 0;
}

.Content {
    display: flex;
    align-items: flex-start;
}

.Content img {
    width: 70px;
    height: 70px;
    border-radius: 3px;
    margin-right: 10px;
}

.Name {
    font-weight: 600;
}

.Description {
    margin-top: 5px;
    font-weight: 500;
    color: #666666;
    font-size: 14px;
}

.Bullets {

}

.Bullet {
    display: flex;
    align-items: center;
    margin: 20px 0;
    font-weight: 600;
    font-size: 15px;
}

.Bullet img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
}
