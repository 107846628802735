.AngleDown {
    height: 60px;
    cursor: pointer;
}

.AngleDown:hover {
    opacity: .8;
}

@media (min-width: 850px) {
    .MobileWrapper {
        display: none;
    }
}

@media (max-width: 850px) {
    .DesktopWrapper {
        display: none;
    }
}